.Scrolling-Animation {
  position: fixed;
  bottom: 0;
  z-index: 51;
  display: flex;
  flex-direction: row;
}

.Animation-Image-Mobile {
  width: 10vw;
}

.Animation-Image-DT {
  width: 5vw;
}
