.Invisible {
  opacity: 0;
}

.Slide-Up {
  animation: slideUp 0.4s forwards linear;
}

@keyframes slideUp {
  0% {
    transform: translateY(5rem);
    opacity: 0;
  }
  75% {
    transform: translateY(10px);
    opacity: 0.9;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.Flow-In > .Title {
  animation: flowInText 0.5s forwards cubic-bezier(0.67, -0.01, 0.43, 1.44);
}

.Flow-In > p {
  animation: flowInText 0.75s forwards cubic-bezier(0.67, -0.01, 0.43, 1.44);
}

@keyframes flowInText {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Flow-In {
  animation: flowIn 0.75s forwards linear;
}

.Fade-Out {
  animation: fadeOut 1s forwards linear;
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.Fade-In {
  animation: fadeIn 1s forwards linear;
}
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes flowIn {
  0% {
    opacity: 0;
    background: radial-gradient(#fff, transparent 0%);
  }
  3.5% {
    background: radial-gradient(#fff, transparent 5%);
  }
  7% {
    background: radial-gradient(#fff, transparent 10%);
  }
  10.5% {
    background: radial-gradient(#fff, transparent 15%);
  }
  14% {
    background: radial-gradient(#fff, transparent 20%);
  }
  17.5% {
    background: radial-gradient(#fff, transparent 25%);
  }

  21% {
    background: radial-gradient(#fff, transparent 30%);
  }
  24.5% {
    background: radial-gradient(#fff, transparent 35%);
  }
  28% {
    background: radial-gradient(#fff, transparent 40%);
  }
  31.5% {
    background: radial-gradient(#fff, transparent 45%);
  }
  35% {
    background: radial-gradient(#fff, transparent 50%);
  }

  38.5% {
    background: radial-gradient(#fff, transparent 55%);
  }
  42% {
    background: radial-gradient(#fff, transparent 60%);
  }
  45.5% {
    background: radial-gradient(#fff, transparent 62.5%);
  }
  49% {
    background: radial-gradient(#fff, transparent 60%);
  }
  52.5% {
    background: radial-gradient(#fff, transparent 55%);
  }
  56% {
    background: radial-gradient(#fff, transparent 50%);
  }
  59.5% {
    background: radial-gradient(#fff, transparent 45%);
  }
  63% {
    background: radial-gradient(#fff, transparent 40%);
  }
  66.5% {
    background: radial-gradient(#fff, transparent 35%);
  }
  70% {
    background: radial-gradient(#fff, transparent 30%);
  }
  73.5% {
    background: radial-gradient(#fff, transparent 25%);
  }
  77% {
    background: radial-gradient(#fff, transparent 20%);
  }
  80.5% {
    background: radial-gradient(#fff, transparent 15%);
  }
  84% {
    background: radial-gradient(#fff, transparent 10%);
  }
  87.5% {
    background: radial-gradient(#fff, transparent 5%);
  }
  91% {
    background: radial-gradient(#fff, transparent 0%);
  }
}
