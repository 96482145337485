.Slide-Up-Pop-Up {
  animation: popSlideUp 0.8s cubic-bezier(0.74, 0.15, 0.3, 1.18) forwards;
}

@keyframes popSlideUp {
  from {
    transform: translateY(100vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.Pop-Up-Container::-webkit-scrollbar {
  width: 0.2em;
  background-color: transparent;
}

.Pop-Up-Container::-webkit-scrollbar-thumb {
  background-color: #fff;
}

.Exit {
  height: min(64px, max(4vw, 4vh));
  width: min(64px, max(4vw, 4vh));
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  display: flex;
  cursor: pointer;
  left: 0px;
}

.Exit:hover {
  background-color: #ffffff40;
}

.TMHeader {
  padding: 1vw;
}

.Main-Content-Mobile {
  width: 85vw;
}
.Main-Content-DT {
  width: 50vw;
}

.Main-Content {
  padding-top: 4vw;
  display: flex;
  flex-direction: column;
  padding-bottom: 4vw;
}

.Text {
  font-size: 1.6rem;
}

.PageTitle {
  font-size: 2.4rem;
}

.PageTitle-Mobile {
  font-size: 2rem;
}

.CS-Paragraph {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.CS-Paragraph-Mobile {
  font-size: 1rem;
}

.CS-Header-Mobile {
  font-size: 1.6rem;
}

.CS-Header {
  padding-top: 4rem;
  font-family: "Chillax-Bold", "sans";
}

.CS-Header-DT {
  font-size: 2.4rem;
}

.Link {
  box-shadow: 0px 0px 4px #000;
  border-radius: 2rem;
  display: flex;
  padding: 2rem;
  width: 5vw;
  height: 5vw;
  cursor: pointer;
}

.Link-Mobile {
  width: 10vw;
  height: 10vw;
  margin-top: 2rem;
}
.Link:hover {
  box-shadow: 0px 0px 4px #fff;
}

.Figma {
  background-color: #323232;
}
.Github {
  background-color: #fff;
}

.Link-Icon {
  width: 100%;
}

.Links {
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  column-gap: 4rem;
  row-gap: 2rem;
}
p {
  user-select: auto;
}
