.No-Show {
  visibility: hidden;
}

.Words-Slide-In {
  animation: slideIn 1.5s ease-in forwards;
}

.Words-Slide-In-Mobile {
  animation: slideInMobile 1s ease-in forwards;
}

@keyframes slideIn {
  0% {
    left: 80vw;
  }
  75% {
    left: 0vw;
  }
  80% {
    left: -2vw;
  }
  90% {
    left: 1vw;
  }
  100% {
    left: 0vw;
  }
}

@keyframes slideInMobile {
  0% {
    left: 80vw;
  }
  75% {
    left: 0vw;
  }
  80% {
    left: -6vw;
  }
  90% {
    left: 3vw;
  }
  100% {
    left: 0vw;
  }
}

.Slide-Up {
  animation: slideUpAboutMe 0.5s cubic-bezier(0.68, 0.22, 0.43, 1.49) forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideUpAboutMe {
  from {
    transform: translateY(5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

h3 {
  font-family: "Chillax";
  font-size: 1.8rem;
  margin: 0px;
  color: #fff;
}

.Not-Expanded-Div {
  justify-content: center;
}

.No-Display {
  display: none;
}
.Grid {
  display: grid;
}
