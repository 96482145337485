.Portfolio {
  background: linear-gradient(#020811, #0b0e11);
}

h2 {
  margin: 0px;
  font-family: "Chillax";
  font-size: 3vw;
  color: #ffffff;
  user-select: none;
}

@keyframes opaque {
  from {
    background-image: linear-gradient(90deg, #ffffffff 10%, #ffffff00);
  }
  to {
    background-image: linear-gradient(90deg, #ffffff, #ffffffff);
  }
}

@keyframes scroll {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-5em);
  }
}

.Portfolio-Item-Description-Div {
  display: flex;
  flex-direction: column;
  top: 1rem;
  align-items: center;
}
.Portfolio-Item-Text-DT {
  justify-content: space-between;
  width: 27vw;
}
.Right {
  right: 0px;
  background: linear-gradient(to left, #32373e 40%, #32373e00);
}
.Left {
  left: 0px;
  background: linear-gradient(to right, #32373eff 40%, #32373e00);
}

.Fade-Out-Box {
  position: absolute;
  height: 24vw;
  width: 6rem;
  z-index: 1;
}

.Portfolio-Container {
  width: 80vw;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.Current-Portfolio-Item {
  width: 74vw;
  display: grid;
  justify-content: center;
  overflow: visible;
}

.Left-Arrow-Mobile {
  position: absolute;
  animation: bounceLeft 3s linear infinite;
  left: -0.5rem;
  width: 8vw;
}
.Right-Arrow-Mobile {
  position: absolute;
  right: -0.5rem;
  animation: bounceRight 3s linear infinite;

  width: 8vw;
}
.Arrows-DT {
  width: 3vw;
}
.Arrows {
  display: flex;
  height: 75%;
  align-items: center;
  z-index: 10;
}
