.Contact {
  background: linear-gradient(#0b0e11, #090412);
  width: 100%;
  z-index: 25;
  padding-top: 6rem;
  padding-bottom: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.Fade-Out-Clipboard {
  animation: fadeOutMessage 2s ease-in-out forwards;
}
@keyframes fadeOutMessage {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
